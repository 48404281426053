
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
// @ts-ignore
import CustomMultiSelect, { Item } from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import ParitySettingsService, { ParitySettingsServiceS } from '../../parity-settings.service';
import ParityFiltersService, { ParityFiltersServiceS } from '../../parity-filters.service';
import BmlFilterType from '../../types/bml-filter.type';

@Component({
    components: { CustomMultiSelect },
})
export default class ParityBmlFilter extends Vue {
    @inject(ParityFiltersServiceS) private parityFiltersService!: ParityFiltersService;
    @inject(ParitySettingsServiceS) private paritySettingsService!: ParitySettingsService;

    get items(): Item[] {
        const bml = ['B', 'M', 'L', 'A'];

        return bml.map(value => ({
            name: value === 'A' ? 'Sold out' : value,
            value,
        }));
    }

    get currentValue() {
        if (!this.paritySettingsService.bml) {
            return [];
        }

        return this.paritySettingsService.bml.map(bml => ({
            name: bml,
            value: bml,
        }));
    }
    set currentValue(value) {
        if (value) {
            this.paritySettingsService.bml = value.map(item => item.value);
        }
    }
}
