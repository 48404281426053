
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import moment from 'moment';
import ParityService, { ParityServiceS } from '@/modules/cars/modules/parity/parity.service';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import { BROKER, BRAND } from '@/modules/cars/constants/data-source-mode.constant';
import LoaderWrapper from '@/modules/common/components/loader-wrapper.vue';
import Currency from '@/modules/common/components/currency.vue';
import ParityFiltersService, { ParityFiltersServiceS } from '../../parity-filters.service';
import { ITableCellItem, ITableHeadItem } from '../../interfaces/grid.interface';

const columns: ITableHeadItem[] = [{
    key: 'scanDate',
    value: 'SCAN DATE',
    scale: 0.8,
}, {
    key: 'broker',
    value: 'BROKER',
    scale: 0.6,
}, {
    key: 'pickUpCity',
    value: 'PICK-UP CITY',
    scale: 1.2,
    sortable: true,
}, {
    key: 'pickUpDate',
    value: 'PICK-UP DATE',
    scale: 1,
}, {
    key: 'lok',
    value: 'LOK',
    scale: 0.5,
}, {
    key: 'carCategory',
    value: 'CAR CATEGORY',
    scale: 1,
}, {
    key: 'brandCarName',
    value: 'BRAND CAR NAME',
    scale: 1.4,
}, {
    key: 'brokerCarName',
    value: 'BROKER CAR NAME',
    scale: 1.4,
}, {
    key: 'pos',
    value: 'POS',
    scale: 0.5,
}, {
    key: 'brandPrice',
    value: 'BRAND PRICE',
    scale: 1,
}, {
    key: 'brokerPrice',
    value: 'BROKER PRICE',
    scale: 1,
}, {
    key: 'priceDif',
    value: 'PRICE DIFF (%)',
    scale: 1.2,
    sortable: true,
}, {
    key: 'bmlClassification',
    value: '',
    scale: 0.4,
}];

@Component({
    components: {
        LoaderWrapper,
        Currency,
    },
    filters: {
        PriceFilter: (value: any) => {
            if (!value) return 'N/A';
            return Number(value).toLocaleString('en-US', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
        },
    },
})
export default class BmlTable extends Vue {
    @inject(ParityServiceS) private parityService!: ParityService;
    @inject(ParityFiltersServiceS) private parityFiltersService!: ParityFiltersService;
    @inject(CarsFiltersServiceS) carsFilterService!: CarsFiltersService;
    @inject(UserServiceS) private userService!: UserService;

    private sortBy: string | null = null;
    private sortOrder: -1 | 1 | null = null;

    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    }

    beforeDestroy() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    get columnGrid() {
        return this.columns.map(column => `${column.scale}fr`).join(' ');
    }

    get columns(): ITableHeadItem[] {
        const { isBrokerMode } = this.carsFilterService;
        const customCols = [...columns];
        if (isBrokerMode) {
            const colSourse = {
                key: 'source',
                value: 'SOURCE',
                scale: 0.6,
            };
            customCols.splice(2, 0, colSourse);
            customCols.forEach(item => {
                if (item.key === 'broker') {
                    // eslint-disable-next-line no-param-reassign
                    item.value = 'BROKER\'S \n BRANDS';
                }
                if (item.key === 'brokerCarName') {
                    // eslint-disable-next-line no-param-reassign
                    item.value = 'SOURCE CAR NAME';
                }
                if (item.key === 'brandCarName') {
                    // eslint-disable-next-line no-param-reassign
                    item.value = 'BROKER’S BRAND CAR NAME';
                }
            });
        }
        return customCols;
    }

    date(date: string) {
        const parsedDate = new Date(date);
        return `${parsedDate.getFullYear()}-${parsedDate.getMonth() + 1}-${parsedDate.getDate()}`;
    }

    get tableData(): ITableCellItem[] | null {
        const { tableDocuments } = this.parityService;

        if (!tableDocuments || !tableDocuments.length) {
            return null;
        }

        const tableData: ITableCellItem[] = tableDocuments.map(document => {
            const data: any = {
                id: document.id,
                scanDate: moment(document.latestBml.scanDate).format('DD/MM/YYYY'),
                broker: document.latestBml.brokerData.providerName,
                pickUpCity: this.parityFiltersService.getCityByCode(document.fnPickUpLocationCode) || document.fnPickUpLocationCode,
                pickUpDate: moment(document.pickUpDate).format('DD/MM/YYYY'),
                lok: document.lor,
                carCategory: document.customerCategoryId,
                brandCarName: document.latestBml.brandData.carDescription,
                brokerCarName: document.latestBml.brokerData.carDescription,
                pos: document.pos,
                brandPrice: document.latestBml.brandData.totalPrice,
                brokerPrice: document.latestBml.brokerData.totalPrice,
                priceDif: (document.latestBml.bmlPriceDiffRatio * 100).toFixed(2),
                bmlClassification: document.latestBml.bmlClassification,
                brandCurrency: document.latestBml.brandData.siteCurrency,
                brokerCurrency: document.latestBml.brokerData.siteCurrency,
            };
            const { chainMode, currentChainCode } = this.carsFilterService;
            const { currentCompany } = this.userService;
            const currentBroker = this.parityService.storeState.settings.provider;
            if (chainMode === BROKER && currentCompany && currentBroker) {
                const [broker, brand] = currentBroker.split(',');
                const brokerName = [currentChainCode, brand].join('_');
                const dataSourceFrom = [document.latestBml.brandData.providerName, document.latestBml.brokerData.providerName];
                data.source = (dataSourceFrom.includes(broker) && dataSourceFrom.includes(brand))
                    ? 'Brand' : (dataSourceFrom.find(item => ![broker, brand].includes(item)) || '');
                data.broker = brokerName;
            }
            return data;
        });

        return tableData;
    }

    handleRowClick(id: number) {
        this.parityService.currentDocumentId = id;
        this.openScreenshot();
    }

    openScreenshot() {
        this.$router.push({ name: 'parity-compare-popup' });
    }

    handleScroll() {
        const minDistanseToBottom = 10;
        const scrollMax = Math.max(
            document.body.scrollHeight,
            document.documentElement.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.offsetHeight,
            document.body.clientHeight,
            document.documentElement.clientHeight,
        );

        const scrollCurrent = Math.round(window.innerHeight + Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop));
        const isBottomOfWindow = Math.abs(scrollMax - scrollCurrent) <= minDistanseToBottom;

        if (isBottomOfWindow) {
            this.parityService.loadMoreTableData();
        }
    }

    handleSortClick(column: ITableHeadItem) {
        if (column.key === this.sortBy) {
            this.sortOrder = (this.sortOrder && this.sortOrder === 1) ? -1 : 1;
        } else {
            this.sortBy = column.key;
            this.sortOrder = 1;
        }

        this.parityService.tableSort = {
            sortBy: this.sortBy,
            sortOrder: this.sortOrder,
        };
    }

    getColor(bmlClassification: 'B' | 'M' | 'L') {
        switch (bmlClassification) {
            case 'B':
                return 'yellow';
            case 'M':
                return 'green';
            case 'L':
                return 'red';
            default:
                return 'grey';
        }
    }
}
