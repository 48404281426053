
import { Component, Vue } from 'vue-property-decorator';
import SpreadGraph from './spread-graph.vue';

@Component({
    components: {
        SpreadGraph,
    },
})
export default class BmlSpread extends Vue {

}
