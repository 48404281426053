
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import ParitySettingsService, { ParitySettingsServiceS } from '@/modules/cars/modules/parity/parity-settings.service';
import ParityFiltersService, { ParityFiltersServiceS } from '@/modules/cars/modules/parity/parity-filters.service';
import StyledSearchbar from '@/modules/common/components/styled-searchbar.vue';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';

@Component({
    components: { CustomSelect, StyledSearchbar },
})
export default class ParityCountryFilter extends Vue {
    @inject(ParityFiltersServiceS) private parityFiltersService!: ParityFiltersService;
    @inject(ParitySettingsServiceS) private paritySettingsService!: ParitySettingsService;
    @inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;

    private query: string | null = null;

    get items(): Item[] {
        let { countriesFilter } = this.parityFiltersService;

        if (!countriesFilter) {
            return [];
        }

        if (this.query) {
            const searchPattern = new RegExp(this.query.toLowerCase());
            countriesFilter = countriesFilter.filter(location => searchPattern.test(location.toLowerCase()));
        }

        const result = countriesFilter.filter(country => this.parityFiltersService.filterPickUpCitiesByCountry(country).length > 0)
            .map(value => ({
                name: String(value),
                disabled: false,
                value,
            })).sort((a, b) => {
                if (a.name <= b.name) {
                    return -1;
                }
                return 1;
            });

        if (!result.length) {
            result.push({
                name: 'No results',
                value: 'No results',
                disabled: true,
            });
        }

        return result;
    }

    handleChange(query: string) {
        this.query = query;
    }

    get currentValue() {
        return this.paritySettingsService.country;
    }
    set currentValue(value) {
        if (value) {
            this.carsSharedService.saveCountry(value);
            this.paritySettingsService.updateLocations();
        }
    }
}
