
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';
import CarHeader from '@/modules/cars/components/car-header.vue';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import BmlTable from '../components/bml-table/bml-table.vue';
import BmlTableInfo from '../components/bml-table/bml-table-info.vue';
import ParityActions from '../components/parity-actions.vue';
import BmlOverTime from '../components/bml-over-time/bml-over-time.vue';
import BmlSpread from '../components/bml-spread/bml-spread.vue';
import ParitySettingsService, { ParitySettingsServiceS } from '../parity-settings.service';

@Component({
    components: {
        PageWrapper,
        CarHeader,
        ParityActions,
        BmlTable,
        BmlTableInfo,
        BmlOverTime,
        BmlSpread,
    },
})
export default class ParityPage extends Vue {
    @inject(ParitySettingsServiceS) private paritySettingsService!: ParitySettingsService;
    @inject(UserServiceS) userService!: UserService;

    mounted() {
        this.paritySettingsService.provider = this.$route.params.provider;
        // if (!this.userService.currentCompany) {
        //     return;
        // }
        // if (this.userService.currentCompany.toLowerCase() === 'rentalcars') {
        //     this.$router.push('/');
        // }
    }

    get brokerName() {
        return this.paritySettingsService.provider;
    }
}
