
import { Component, Vue } from 'vue-property-decorator';
import TrandGraph from './trand-graph.vue';

@Component({
    components: {
        TrandGraph,
    },
})
export default class BmlOverTime extends Vue {

}
