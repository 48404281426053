
import { Prop, Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import ParityCarClassesFilter from '@/modules/cars/modules/parity/components/parity-filters/parity-car-classes-filter.vue';
import ParityCountryFilter from '@/modules/cars/modules/parity/components/parity-filters/parity-country-filter.vue';
import ParityLokFilter from '@/modules/cars/modules/parity/components/parity-filters/parity-lok-filter.vue';
import ParityMileageFilter from '@/modules/cars/modules/parity/components/parity-filters/parity-mileage-filter.vue';
import ParityPaymentTermsFilter from '@/modules/cars/modules/parity/components/parity-filters/parity-payment-terms-filter.vue';
import ParityPickUpCityFilter from '@/modules/cars/modules/parity/components/parity-filters/parity-pick-up-city-filter.vue';
import ParityDataSourcesFilter from '@/modules/cars/modules/parity/components/parity-filters/parity-data-sources-filter.vue';
import ParityPosFilter from '@/modules/cars/modules/parity/components/parity-filters/parity-pos-filter.vue';
import ParityTransmissionFilter from '@/modules/cars/modules/parity/components/parity-filters/parity-transmission-filter.vue';
import PARITY_FILTER from '@/modules/cars/modules/parity/constants/parity-filter.constant';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import ParityBmlFilter from './parity-filters/bml-filter.vue';

@Component({
    components: {
        ParityPickUpCityFilter,
        ParityLokFilter,
        ParityPosFilter,
        ParityTransmissionFilter,
        ParityMileageFilter,
        ParityPaymentTermsFilter,
        ParityCountryFilter,
        ParityCarClassesFilter,
        ParityBmlFilter,
        ParityDataSourcesFilter,
    },
})

export default class ParityFilterSet extends Vue {
    @inject(CarsFiltersServiceS) carsFilterService!: CarsFiltersService;

    @Prop({
        type: Array,
        required: true,
    })
    activeFilters!: PARITY_FILTER[];

    isCountry(filterName: PARITY_FILTER) { return filterName === PARITY_FILTER.COUNTRY; }
    isPickup(filterName: PARITY_FILTER) { return filterName === PARITY_FILTER.PICKUP; }
    isDataSourcesFilter(filterName: PARITY_FILTER) {
        return filterName === PARITY_FILTER.DATA_SOURCES && this.carsFilterService.isBrokerMode;
    }
    isLok(filterName: PARITY_FILTER) { return filterName === PARITY_FILTER.LOK; }
    isPos(filterName: PARITY_FILTER) { return filterName === PARITY_FILTER.POS; }
    isCarCategory(filterName: PARITY_FILTER) { return filterName === PARITY_FILTER.CAR_CATEGORY; }
    isTransmission(filterName: PARITY_FILTER) { return filterName === PARITY_FILTER.TRANSMISSION; }
    isMileage(filterName: PARITY_FILTER) { return filterName === PARITY_FILTER.MILEAGE; }
    isPaymentTerms(filterName: PARITY_FILTER) { return filterName === PARITY_FILTER.PAYMENT_TERMS; }
    isBml(filterName: PARITY_FILTER) { return filterName === PARITY_FILTER.BML; }
}
