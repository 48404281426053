enum PARITY_FILTER {
    COUNTRY= 'country',
    PICKUP = 'pickup',
    DATA_SOURCES = 'data-sources',
    POS = 'pos',
    LOK = 'lok',
    CAR_CATEGORY = 'car-category',
    TRANSMISSION = 'transmission',
    MILEAGE = 'mileage',
    PAYMENT_TERMS = 'payment-terms',
    BML = 'bml',

}

export default PARITY_FILTER;
