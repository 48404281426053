
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import ParityFiltersService, { ParityFiltersServiceS } from '../../parity-filters.service';
import ParitySettingsService, { ParitySettingsServiceS } from '../../parity-settings.service';
import CustomMultiSelect from '../../../../../common/components/ui-kit/custom-multi-select.vue';

@Component({
    components: { CustomMultiSelect },
})
export default class ParityPaymentTermsFilter extends Vue {
    @inject(ParityFiltersServiceS) private parityFiltersService!: ParityFiltersService;
    @inject(ParitySettingsServiceS) private paritySettingsService!: ParitySettingsService;

    get items(): Item[] {
        const { paymentTerms } = this.parityFiltersService.settings;

        if (!paymentTerms) {
            return [];
        }

        return paymentTerms.map(value => ({
            name: String(value),
            value,
        }));
    }

    get currentValue() {
        if (!this.paritySettingsService.storeState.settings.paymentTerm) {
            return [];
        }

        return this.paritySettingsService.storeState.settings.paymentTerm.map(paymentTerm => ({
            name: paymentTerm,
            value: paymentTerm,
        }));
    }
    set currentValue(value) {
        if (value) {
            this.paritySettingsService.storeState.settings.paymentTerm = value.map(item => item.value);
        }
    }
}
