
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
// @ts-ignore
import CustomMultiSelect, { Item } from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import ParitySettingsService, { ParitySettingsServiceS } from '../../parity-settings.service';
import ParityFiltersService, { ParityFiltersServiceS } from '../../parity-filters.service';

@Component({
    components: { CustomMultiSelect },
})
export default class ParityLokFilter extends Vue {
    @inject(ParityFiltersServiceS) private parityFiltersService!: ParityFiltersService;
    @inject(CarsServiceS) private carsService!: CarsService;
    @inject(ParitySettingsServiceS) private paritySettingsService!: ParitySettingsService;
    @inject(UserServiceS) private userService!: UserService;

    get items(): Item[] {
        let { lor } = this.parityFiltersService.settings;

        if (!lor) {
            return [];
        }

        if (this.userService && this.userService.user && this.userService.user.chainName === 'Rentalcars') {
            const lorsToInclude = [3, 7, 10, 14];
            lor = lor.filter(item => lorsToInclude.includes(item));
        } else if (this.userService && this.userService.user && this.userService.user.chainName === 'Hertz') {
            const lorsToInclude = [1, 2, 3, 4, 5, 7, 28];
            lor = lor.filter(item => lorsToInclude.includes(item));
        } else if (this.userService && this.userService.user && this.userService.user.chainName === 'Lyft') {
            const lorsToInclude = [1, 2, 5, 7, 14, 28];
            lor = lor.filter(item => lorsToInclude.includes(item));
        } else if (this.userService && this.userService.user && this.userService.user.chainName === 'Avis') {
            const lorsToInclude = [1, 7];
            lor = lor.filter(item => lorsToInclude.includes(item));
        }

        return lor.map(value => ({
            name: String(value),
            value,
        }));
    }

    get currentValue() {
        if (!this.paritySettingsService.storeState.settings.lor) {
            return [];
        }

        return this.paritySettingsService.storeState.settings.lor.map(lor => ({
            name: lor,
            value: lor,
        }));
    }
    set currentValue(value) {
        if (value) {
            this.paritySettingsService.lor = value.map(item => item.value);
        }
    }
}
